import React from 'react';
import * as styles from './services.module.css';

import Container from '../../components/Container';
import Layout from '../../components/Layout';
import Image from '../../components/Image';
import { Link } from 'gatsby';

const RemodelPage = () => {
  return (<Layout >
    <div className={styles.root}>
        <Container>
            <h1 className={styles.header} data-fade-in-section>Remodel / Resurface</h1>
            <div data-fade-in-section className={styles.gridLayout}>
                <h2 className={styles.header}>Inground vinyl pool remodel</h2>
                <p>
                    When it comes time to reline your inground vinyl pool, you may consider a more extensive upgrade. Whether it is adding or modifying a step, changing out a skimmer or installing an LED color-changing light to your pool, we can do it all. To reinforce and repair older pools, it may be necessary to reinforce your pool walls, add new track, or upgrade plumbing. 
                </p>
                <img className={styles.image} height={260} src="/remodel_1.jpeg"></img>
                <img className={styles.image} height={260} src="/remodel_2.jpeg"></img>
            </div>
            <div className={styles.gridLayout}>
                <h2 className={styles.header}>Fiberglass Resurface</h2>
                <p>
                    Fiberglass resurfacing may be the best option for older inground vinyl pools with structural issues. Our partner, Advanced Pool Coatings, resurfaces all types of pools and spas with their Advanced-Glass technology. Their patented system provides a durable, long-lasting fiberglass structural layer and a smooth finish coat that will transform an older pool into a worry-free, “like-new” pool.
                    To learn more about the fiberglass resurfacing process, visit <a href="http://www.advancedpoolcoatings.com/" target="_blank">Advanced Pool Coatings' website</a>. 
                    We would be happy to discuss this option with you.
                </p>
                <Image data-fade-in-section height={480} src="/reline_pool_after_1.jpeg"></Image>
                <Image data-fade-in-section height={480} src="/reline_pool_after_2.jpeg"></Image>
                <Image data-fade-in-section className={styles.image} height={480} src="/reline_pool2_before.jpeg" caption='Vinyl pool transformed with fiberglass "Advanced-Glass" system by Advanced Pool Coatings'></Image>
                <Image data-fade-in-section className={styles.image} height={480} src="/reline_pool2_after.jpeg"></Image>
            </div>
        </Container>
    </div>
  </Layout>);
};

export default RemodelPage;